import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import { Link, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Container } from '@mui/material';
import { useAuth } from '../../context/authContext';
import React, { useState, useEffect, useRef } from 'react';
import { useSnackbar } from 'notistack';
import { AxiosError } from 'axios';

const Login = () => {
  const navigate = useNavigate();
  const { emailConfirmation } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();
  const expires = searchParams.get('expires');
  const signature = searchParams.get('signature');
  let urlParams = useParams();
  const hash = urlParams.hash;
  const userId = urlParams.id;
  const { t } = useTranslation();
  const runningVerification = useRef<boolean>(false);
  useEffect(() => {
    if (!hash || !userId || !expires || !signature) {
      setLoading(false);
      setError(t('Invalid URL'));
      return;
    }
    const doConfirmation = async () => {
      try {
        runningVerification.current = true;
        const response = await emailConfirmation?.(userId, hash, expires, signature);
        if (response) {
          enqueueSnackbar(response.data.message, { variant: 'success' });
          navigate('/login');
        }
      } catch (e: unknown) {
        if ((e as AxiosError).response) {
          enqueueSnackbar((e as AxiosError).response?.data.message, { variant: 'error' });
        }
        setLoading(false);
      }
    };

    if (!runningVerification.current) {
      doConfirmation();
    }
    //eslint-disable-next-line
  }, [userId, hash, expires, signature]);
  return (
    <div className="min-h-[calc(100vh-56px-30px)]">
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl ">ACCOUNT CONFIRMATION</h1>
          </div>
        </Container>
      </div>
      <Container maxWidth="sm">
        <div className="flex flex-col items-center h-full mt-12">
          {loading && <CircularProgress />}
          {error && (
            <>
              <div className="font-xl text-center mb-6">{error}</div>
              <Link
                component={RouterLink}
                to="/"
                className=" text-right uppercase cursor-pointer text-slate-500 underline active:text-lightblue hover:text-lightblue-500">
                {t('Back to home')}
              </Link>
            </>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Login;
