import { useState, useEffect } from 'react';
import { useCart } from 'react-use-cart';
import Button from '../../components/Buttons/Button';
import { useNavigate } from 'react-router-dom';
const PaymentSuccess = () => {
  const [message, setMessage] = useState<string | undefined>(undefined);
  const { emptyCart } = useCart();
  const navigate = useNavigate();

  useEffect(() => {
    const redirectStatus = new URLSearchParams(window.location.search).get('redirect_status');
    switch (redirectStatus) {
      case 'succeeded':
        emptyCart();
        setMessage('Payment succeeded!');
        break;
      case 'processing':
        setMessage('Your payment is processing.');
        break;
      default:
        setMessage('Something went wrong.');
        break;
    }
  }, [emptyCart]);

  return (
    <div className="flex justify-center items-center flex-col mt-12 min-h-[calc(100vh-56px-30px)]">
      <div>
        <svg
          width="61"
          height="60"
          viewBox="0 0 61 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.5 30C0.5 13.4315 13.9315 0 30.5 0C47.0685 0 60.5 13.4315 60.5 30C60.5 46.5685 47.0685 60 30.5 60C13.9315 60 0.5 46.5685 0.5 30Z"
            fill="#09C98C"
          />
          <path
            d="M38.08 22.8999L27.5884 33.3915L22.9021 28.5588L22.9022 28.5588L22.899 28.5557C22.6821 28.3387 22.2973 28.1649 21.7766 28.1649C21.4013 28.1649 21.0421 28.3448 20.7575 28.4873C20.7481 28.492 20.7388 28.4967 20.7296 28.5013L20.643 28.5445L20.5997 28.6311C20.416 28.9985 20.4161 29.3637 20.4162 29.6588C20.4162 29.6652 20.4162 29.6717 20.4162 29.6781C20.4162 30.0533 20.5961 30.4126 20.7386 30.6972C20.7433 30.7066 20.748 30.7159 20.7526 30.7251L20.7737 30.7672L20.807 30.8005L26.6156 36.6092C26.7208 36.7143 26.8641 36.8074 26.9968 36.8737C27.1203 36.9355 27.2854 36.9999 27.4323 36.9999H27.5852H27.7381C28.1643 36.9999 28.3914 36.7727 28.5471 36.6169L28.5548 36.6092L40.0192 25.1448C40.7206 24.4433 40.7661 23.4939 40.1721 22.8999C39.6001 22.3279 38.652 22.3279 38.08 22.8999Z"
            fill="white"
            stroke="white"
            stroke-width="0.580645"
          />
        </svg>
      </div>
      <div className="tracking-widest uppercase text-lg my-4">{message}</div>
      <div className="max-w-[300px] text-center mb-4">
        Thank you for your purchases, you will soon receive an email with your license and invoice.
      </div>
      <Button onClick={() => navigate('/', { replace: true })}>Go to home</Button>
    </div>
  );
};

export default PaymentSuccess;
