import { VFC } from 'react';
import {DialogContent, Dialog, Button} from '@mui/material';

const AccountCreationSuccessModal: VFC<{ onClose: () => void }> = ({ onClose }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" scroll="body">
      <DialogContent className="p-0 flex justify-center items-center flex-col min-w-[400px]">
        <img src="/assets/images/plain_engarde_logo.svg" className="my-12 max-w-[56px]" alt="" />
        <h1 className="text-center text-xl tracking-widest uppercase max-w-[300px]">
          thank you for your registration
        </h1>

        <div className="max-w-[320px]">
          <p className="my-6 text-center">
            An email has been sent to you to validate your account. Please validate your account to
            be able to continue with your purchase.
          </p>

          <p className="mb-10  text-center"> Licenses and invoices will be sent to your email at the end of the process.</p>
        </div>
          <Button onClick={onClose} className="w-full bg-black text-white py-3 rounded-none hover:bg-slate-800">Continue</Button>
      </DialogContent>
    </Dialog>
  );
};

export default AccountCreationSuccessModal;
