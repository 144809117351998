import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IcPassword: React.FC<SvgIconProps> = ({ className, ...props }) => {
  return (
    <SvgIcon className={className} viewBox="0 0 14 19" {...props}>
      <g>
        <path
          d="M7,0.1c-2.4,0-4.4,2-4.4,4.4v1.8H0.7C0.4,6.3,0.1,6.6,0.1,7v5c0,3.8,3.1,6.9,6.9,6.9c3.8,0,6.9-3.1,6.9-6.9V7
		c0-0.4-0.3-0.7-0.7-0.7h-1.8V4.5C11.4,2,9.4,0.1,7,0.1z M3.9,4.5c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1v1.8H3.9V4.5z
		 M12.6,7.7V12c0,3.1-2.5,5.6-5.6,5.6c-3.1,0-5.6-2.5-5.6-5.6V7.7H12.6z"
        />
        <path
          d="M7,10.1c-0.4,0-0.7,0.3-0.7,0.7v2.5c0,0.4,0.3,0.7,0.7,0.7c0.4,0,0.7-0.3,0.7-0.7v-2.5
		C7.7,10.4,7.4,10.1,7,10.1z"
        />
      </g>
    </SvgIcon>
  );
};

export default IcPassword;
