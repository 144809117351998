import { createTheme, Theme, Palette } from '@mui/material/styles';

const primaryMain = '#ff8600';

const palette: Partial<Palette> = {
  mode: 'light',
  primary: {
    light: '#A8DDE9',
    main: '#67C3D8',
    dark: '#2A8EA5',
    contrastText: '#ffffff',
  },
  secondary: {
    light: '#b5efdd',
    main: '#09C98C',
    dark: '#05b56f',
    contrastText: '#ffffff',
  },
};

let theme = {
  palette,
  themeName: 'scouting-light',
  typography: {
    fontFamily: [
      'Montserrat',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ].join(','),
  },
};

const globalTheme: Theme = createTheme(theme);

const MuiAppBar = {
  styleOverrides: {
    root: { backgroundColor: primaryMain },
    colorPrimary: { backgroundColor: '#2c323f', color: theme.palette?.common?.white },
  },
};

const MuiDrawer = {
  styleOverrides: {
    paper: { backgroundColor: '#2c323f', color: 'white' },
  },
};

const MuiButton = {
  styleOverrides: {
    // Name of the slot
    root: {
      // Some CSS
      fontSize: '15px',
      letterSpacing: '0.11em',
      fontWeight: 'bold',
      height: 40,
    },
  },
};

const MuiListItem = {
  styleOverrides: {
    root: {
      '&$selected': {
        backgroundColor: theme.palette?.common?.black,
        color: theme.palette?.common?.white,
        '&:hover': {
          backgroundColor: theme.palette?.common?.black,
          color: theme.palette?.common?.white,
        },
      },
    },
    button: {
      color: theme.palette?.common?.white,
    },
  },
};

const MuiInput = {
  styleOverrides: {
    underline: {
      '&::before': {
        borderBottom: '1px solid #dedede',
      },
    },
  },
};

const MuiToggleButton = {
  styleOverrides: {
    root: {
      height: 32,
      color: theme.palette?.primary?.main,
      borderRadius: '50%',
      padding: '0 3px',
      borderColor: 'transparent',
      '&:hover': {
        color: theme.palette?.primary?.main,
        backgroundColor: theme.palette?.primary?.light,
      },
      '&.Mui-selected': {
        boxShadow:
          '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.2)',
        backgroundColor: theme.palette?.primary?.main,
        color: theme.palette?.common?.white,
        border: 0,
        '&:hover': {
          backgroundColor: `${theme.palette?.primary?.dark} !important`,
        },
      },
    },
  },
};

const MuiTabs = {
  styleOverrides: {
    indicator: {
      justifyContent: 'center',
      backgroundColor: theme.palette?.primary?.main,
      height: 4,
    },
    root: {
      backgroundColor: theme.palette?.common?.white,
      borderBottom: 'solid 1px rgba(0, 0, 0, 0.12)',
    },
  },
};

const MuiTab = {
  styleOverrides: {
    root: {
      color: globalTheme.palette.text.primary,
      fontWeight: globalTheme.typography.fontWeightRegular,
      fontSize: globalTheme.typography.pxToRem(13),
      minWidth: 0,
      padding: '22px 12px',
      '&:focus': {
        opacity: 1,
      },
      '&.Mui-selected': {
        color: globalTheme.palette.text.primary,
        fontWeight: 700,
      },
    },
  },
};

const themeInstance: Theme = createTheme(
  {
    components: {
      MuiButton,
      MuiAppBar,
      MuiListItem,
      MuiDrawer,
      MuiToggleButton,
      MuiInput,
      MuiTabs,
      MuiTab,
    },
  },
  globalTheme,
);

export default themeInstance;
