const fr = {
    "messages": {
        "unknown_error": "Bogue inconnue. Veuillez contacter le support technique.",
        "unauthorized": "Les informations d'identification invalides.",
        "forbidden": "Nous sommes désolés. Votre utilisateur est inactif ou n'a pas accès à cette ressource. Pour plus d'informations, contactez le support technique.",
        "operation_failed": "Opération ratée. Vérifiez que vous n'essayez pas de saisir des données en double et que vous n'essayez pas de supprimer des données utilisées par un autre élément du système.",
        "operation_ok": "Opération terminée.",
        "unprocessable_entity": "Veuillez vérifier les données que vous essayez de saisir.",
        "registration_ok": "Inscription réussie ! Vous pourrez désormais vous connecter et accéder à nos produits.",
        "empty_cart": "Votre panier est vide",
        "must_login_for_this_action": "Vous devez être connecté pour effectuer cette action",
        "authentication_ok": "Authentification réussie",
        "purchase_ok": "Opération terminée. Vous recevrez bientôt la licence par e-mail. Merci!",
        "purchase_error": "L'opération a échoué. Veuillez réessayer plus tard.",
        "purchase_cancelled": "Opération annulée",
        "invoice_generation_started": "La facture est en cours de génération et sera téléchargée automatiquement...",
        "product_added_to_card": "Un produit a été ajouté à votre panier",
        "product_removed_from_card": "Un produit a été supprimé de votre panier"
    },
    "text": {
        "dont_have_an_account": "Vous n'avez pas de compte ?",
        "forgot_your_password": "Mot de passe oublié?",
        "request_error": "Erreur de demande. Veuillez réessayer plus tard.",
        "loading": "Chargement",
        "error_fetching_data": "Nous sommes désolés. Une erreur s'est produite lors de la récupération des données.",
        "type_to_search": "Tapez pour rechercher",
        "filter_by": "Filtrer par",
        "sort": "Sorte",
        "sort_direction": "Sens de tri",
        "asc": "Ascendant",
        "desc": "Desc",
        "per_page": "Par page",
        "no_records_matching_your_request": "Il n'y a aucun enregistrement correspondant à votre demande",
        "no_records_available": "Il n'y a pas d'enregistrements disponibles.",
        "are_you_sure_delete": "Êtes-vous sûr? Cette action ne peut pas être annulée!",
        "are_you_sure_revoke": "Êtes-vous sûr? Vous devrez vous reconnecter sur cet appareil !",
        "are_you_sure_clone": "Are you sure? The item will be disabled and a copy will be created.",
        "password_and_confirmation_are_not_equal": "Le mot de passe et la confirmation doivent être identiques.",
        "valid": "Valide",
        "invalid": "Invalide",
        "expired": "Expiré",
        "too_many_requests": "Vous avez fait de nombreuses demandes. S\"il vous plait attendez quelques minutes et réessayez",
        "search_by": "Rechercher par",
        "users": "Utilisateurs",
        "our_products": "Nos produits",
        "cart": "Votre panier",
        "total": "Le total",
        "products": "Produits",
        "orders": "Ordres",
        "order": "Ordre",
        "error": "Erreur",
        "detail": "Détail",
        "france_vat_condition": "France: Indiquer votre numéro SIRET",
        "eu_vat_condition": "UE: Indiquer votre numéro de TVA intracommunautaire ou votre identifiant fiscal",
        "worl_vat_condition": "Autres pays: Indiquer votre numéro fiscal",
        "confirmation": "Confirmation",
        "vat_without_country_code": "TVA sans code pays",
        "successful_purchase_title": "Achat réussi",
        "successful_purchase_thanks": "Merci pour vos achats, vous allez bientôt recevoir un email avec votre licence et votre facture",
        "download_software_text": "Vous pouvez télécharger et installer notre logiciel en cliquant",
        "here": "ici"
    },
    "fields": {
        "email": "E-mail",
        "password": "Mot de passe",
        "new_password": "Nouveau mot de passe",
        "password_confirmation": "Confirmation mot de passe",
        "name": "Nom",
        "first_name": "Prénom",
        "last_name": "Nom de famille",
        "description": "La description",
        "title_or_description": "Titre ou description",
        "status": "Statut",
        "actions": "Actions",
        "updated_at": "Mise à jour",
        "role": "Rôle",
        "menu": "Menu",
        "icon": "Icône",
        "route": "Route",
        "create": "Créer",
        "read": "Lis",
        "update": "Mettre à jour",
        "delete": "Effacer",
        "phone": "Téléphoner",
        "country": "Pays",
        "province": "Province",
        "street_address": "Adresse",
        "postcode": "Code postal",
        "district": "Quartier",
        "zone": "Zone",
        "device": "Appareil",
        "city": "Ville",
        "company": "Compagnie",
        "suburb": "Complément d'adresse",
        "state": "Etat/Département",
        "transaction": "Transaction",
        "price": "Le prix",
        "permission": "Autorisation",
        "date": "Date",
        "client": "Client",
        "to_name": "Facture au nom de",
        "fiscal_id": "TVA",
        "percentage": "Pourcentage",
        "region": "Région",
        "vat": "Numéro fiscal",
        "image": "Image"
    },
    "actions": {
        "back": "Dos",
        "next": "Suivant",
        "save": "Sauvegarder",
        "save_and_new": "Enregistrer et créer un nouveau",
        "new_item": "Nouvel article",
        "new": "Nuevo",
        "select": "Seleccionar",
        "continue": "Continuer",
        "login": "Entrer dans",
        "validate": "Valider",
        "sign_in": "S'inscrire",
        "sign_up": "Créer un compte",
        "logout": "Se déconnecter",
        "my_profile": "Mon profil",
        "recover_password": "Récupérer mot de passe",
        "recovery": "Récupération",
        "change_password": "Changer le mot de passe",
        "clear": "Dégager",
        "try_again": "Réessayer",
        "show_actions": "Afficher les actions",
        "view": "Vue",
        "edit": "Éditer",
        "delete": "Effacer",
        "ok_sure": "Oui, je suis sûr.",
        "no_cancel": "Non, annulez.",
        "add_menu": "Ajouter un menu",
        "add_image": "Ajouter une image",
        "delete_image": "Supprimer l\"image",
        "add_characteristic": "Ajouter une caractéristique",
        "show_owner_info": "Afficher les informations sur le propriétaire",
        "hide_owner_info": "Masquer les informations du propriétaire",
        "download_images": "Télécharger des images",
        "refresh": "Rafraîchir",
        "show_description": "Montrer la description",
        "pay": "Payer",
        "actions": "Actions",
        "download_invoice": "Télécharger la facture",
        "cancel": "Annuler",
        "contact": "Contact",
        "clone_and_disable": "Cloner et désactiver"
    },
    "navigation": {
        "dashboard": "Tableau de bord",
        "products": "Produits",
        "devices": "Appareils",
        "orders": "Commandes",
        "users": "Utilisateurs",
        "roles": "Rôles",
        "permissions": "Autorisations",
        "status": "Statut",
        "taxes": "Impôts"
    },
    "validations": {
        "alpha": "Le champ ne peut contenir que des lettres",
        "alpha_num": "Le champ ne peut contenir que des caractères alpha-numériques",
        "alpha_dash": "Le champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés",
        "alpha_spaces": "Le champ ne peut contenir que des lettres ou des espaces",
        "between": "Le champ doit être compris entre {min} et {max}",
        "confirmed": "Le champ ne correspond pas",
        "digits": "Le champ doit être un nombre entier de {length} chiffres",
        "dimensions": "Le champ doit avoir une taille de {width} pixels par {height} pixels",
        "email": "Le champ doit être une adresse e-mail valide",
        "excluded": "Le champ doit être une valeur valide",
        "ext": "Le champ doit être un fichier valide",
        "image": "Le champ doit être une image",
        "integer": "Le champ doit être un entier",
        "length": "Le champ doit contenir {length} caractères",
        "max_value": "Le champ doit avoir une valeur de {max} ou moins",
        "max": "Le champ ne peut pas contenir plus de {length} caractères",
        "mimes": "Le champ doit avoir un type MIME valide",
        "min_value": "Le champ doit avoir une valeur de {min} ou plus",
        "min": "Le champ doit contenir au minimum {length} caractères",
        "numeric": "Le champ ne peut contenir que des chiffres",
        "oneOf": "Le champ doit être une valeur valide",
        "regex": "Le champ doit contenir entre 6 et 12 caractères, au moins un chiffre, une lettre majuscule et un caractère spécial (@$!%*?&).",
        "required": "Le champ est obligatoire",
        "required_if": "Le champ est obligatoire lorsque {target} possède cette valeur",
        "size": "Le champ doit avoir un poids inférieur à {size}KB",
        "double": "Le champ doit être une décimale valide",
        "is": "Le champ ne correspond pas",
        "unique": "Le champ doit être unique"
    }
}

export default fr
