import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField, InputAdornment, Link } from '@mui/material';
import IcMail from '../../components/Icons/IcMail';
import { showError } from '../../helpers/ValidationHelpers';
import IcPassword from '../../components/Icons/IcPassword';
import { useTranslation } from 'react-i18next';
import Button from '../../components/Buttons/Button';
import { Link as RouterLink } from 'react-router-dom';
import { Container } from '@mui/material';
import { useAuth } from '../../context/authContext';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import formatErrors from '../../helpers/formatErrors';
import getDeviceName from '../../helpers/getDeviceName';

type LoginFormDefinition = {
  email: string;
  password: string;
};
export const EMAIL_REGEX: RegExp =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormDefinition>({ defaultValues: { email: '', password: '' } });
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();
  let from = (location.state as any)?.from?.pathname || '/';

  const [isPendingLogin, setIsPendingLogin] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();
  const onSubmit = async (values: any) => {
    setIsPendingLogin(true);
    try {
      if (login) {
        await login({ ...values, device: getDeviceName() });
        setTimeout(() => {
          navigate(from, { replace: true });
          enqueueSnackbar(t('Logged In Successfully!'), { variant: 'success' });
        }, 1000);
      }
      setIsPendingLogin(false);
    } catch (e: any) {
      formatErrors(e).map((error: string) => enqueueSnackbar(error, { variant: 'error' }));
      setIsPendingLogin(false);
    }
  };

  const handleForgotPasswordClick = () => {
    navigate('/forgot-password');
  };

  return (
    <div className="min-h-[calc(100vh-56px-30px)]">
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl ">LOGIN</h1>
          </div>
        </Container>
      </div>

      <Container maxWidth="sm">
        <form className="flex flex-col items-center h-full mt-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-10 max-w-xl w-full">
            <div className="flex flex-col sm-portrait:flex-row md:flex-col">
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: t('Please input your email!') as string,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('You must enter a valid Email address!'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      error={Boolean(errors.email)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcMail className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      label={t('Email')}
                      placeholder={t('Email')}
                      className="w-full group"
                      helperText={showError(errors, 'email', ['required', 'pattern'])}
                    />
                  )}
                />
              </div>
              <div className="mb-5 w-full">
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: t<string>('Please input your Password!') }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      error={Boolean(errors.password)}
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcPassword className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      label={t('Password')}
                      className="w-full"
                      placeholder={t('Password')}
                      helperText={showError(errors, 'password', ['required'])}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-6">
            <Button disabled={isPendingLogin} type="submit">
              {t('Log In')}
            </Button>
          </div>
          <div className="w-full flex justify-center max-w-xl">
            <div
              onClick={handleForgotPasswordClick}
              className="py-4 pr-12 cursor-pointer underline hover:text-lightblue-500">
              {t('Forgot Password?')}
            </div>
            <Link
              component={RouterLink}
              to="/create-account"
              className="py-4 text-right pl-2 cursor-pointer text-slate-500 underline active:text-lightblue hover:text-lightblue-500">
              {t('Create Account')}
            </Link>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default Login;
