const en = {
    "messages": {
        "unknown_error": "Unknown bug. Please contact Technical Support.",
        "unauthorized": "Invalid credentials.",
        "forbidden": "We're sorry. Your user is inactive or does not have access to this resource. For more information, contact technical support.",
        "operation_failed": "Operation failed. Check that you are not trying to enter duplicate data and that you are not trying to delete data used by another element of the system.",
        "operation_ok": "Operation completed.",
        "unprocessable_entity": "Please check the data you are trying to enter.",
        "registration_ok": "Successful registration ! You will now be able to log in and access our products.",
        "empty_cart": "Your cart is empty",
        "must_login_for_this_action": "You must be logged in to perform this action",
        "authentication_ok": "Authentication successful",
        "purchase_ok": "Operation completed. You will soon receive the license by e-mail. Thank you!",
        "purchase_error": "Operation failed. Please try again later.",
        "purchase_cancelled": "Operation cancelled",
        "invoice_generation_started": "The invoice is being generated and will be downloaded automatically...",
        "product_added_to_card": "A product has been added to your cart",
        "product_removed_from_card": "A product has been removed from your cart"
    },
    "text": {
        "dont_have_an_account": "Don't have an account?",
        "forgot_your_password": "Forgot your password?",
        "request_error": "Request error. Please try again later.",
        "loading": "Loading",
        "error_fetching_data": "We're sorry. An error occurred while retrieving data.",
        "type_to_search": "Type to search",
        "filter_by": "Filter by",
        "sort": "Sort",
        "sort_direction": "Sort direction",
        "asc": "Asc",
        "desc": "Desc",
        "per_page": "Per page",
        "no_records_matching_your_request": "There are no records matching your request",
        "no_records_available": "There are no records available.",
        "are_you_sure_delete": "Are you sure? This action cannot be undone!",
        "are_you_sure_revoke": "Are you sure? You will need to log in again on this device!",
        "are_you_sure_clone": "Are you sure? The item will be disabled and a copy will be created.",
        "password_and_confirmation_are_not_equal": "Password and confirmation must match.",
        "valid": "Valid",
        "invalid": "Invalid",
        "expired": "Expired",
        "too_many_requests": "You have made many requests. Please wait a few minutes and try again",
        "search_by": "Search by",
        "users": "Users",
        "our_products": "Our products",
        "cart": "Your cart",
        "total": "Total",
        "products": "Products",
        "orders": "Orders",
        "order": "Order",
        "error": "Error",
        "detail": "Detail",
        "all_prices_are_shown_without_taxes": "All prices are shown without taxes",
        "france_vat_condition": "France: Enter SIRET",
        "eu_vat_condition": "EU: Enter intra-community VAT number or fiscal ID",
        "worl_vat_condition": "Other countries: Enter fiscal ID",
        "confirmation": "Confirmation",
        "vat_without_country_code": "VAT without country code",
        "successful_purchase_title": "Successful purchase",
        "successful_purchase_thanks": "Thank you for your purchases, you will soon receive an email with your license and invoice",
        "download_software_text": "You can download and install our software by clicking",
        "here": "here"
    },
    "fields": {
        "email": "E-mail",
        "password": "Password",
        "new_password": "New password",
        "password_confirmation": "Confirm password",
        "name": "Name",
        "last_name": "Last name",
        "first_name": "First name",
        "description": "Description",
        "title_or_description": "Title or description",
        "status": "Status",
        "actions": "Actions",
        "updated_at": "Update",
        "role": "Role",
        "menu": "Menu",
        "icon": "Icon",
        "route": "Road",
        "create": "Create",
        "read": "Read",
        "update": "Update",
        "delete": "Delete",
        "phone": "Phone",
        "country": "Country",
        "province": "Province",
        "street_address": "Address",
        "postcode": "Postal code",
        "district": "District",
        "zone": "Zone",
        "device": "Device",
        "city": "City",
        "company": "Company",
        "suburb": "Additional address",
        "state": "State/Department",
        "transaction": "Transaction",
        "price": "Price",
        "permission": "Authorization",
        "date": "Date",
        "client": "Client",
        "to_name": "Full name for invoice",
        "fiscal_id": "VAT",
        "percentage": "Percentage",
        "region": "Region",
        "vat": "VAT Number",
        "image": "Image"
    },
    "actions": {
        "back": "Dos",
        "next": "Next",
        "save": "Save",
        "save_and_new": "Save and create a new",
        "new_item": "New article",
        "new": "New",
        "select": "Seleccionar",
        "continue": "Continue",
        "login": "Enter",
        "validate": "Validate",
        "sign_in": "Register",
        "sign_up": "Create an account",
        "logout": "Sign out",
        "my_profile": "My profile",
        "recover_password": "Recover password",
        "recovery": "Recovery",
        "change_password": "Change password",
        "clear": "Clear",
        "try_again": "Retry",
        "show_actions": "Display actions",
        "view": "View",
        "edit": "Edit",
        "delete": "Delete",
        "ok_sure": "Yes, I'm sure.",
        "no_cancel": "No, cancel.",
        "add_menu": "Add menu",
        "add_image": "Add image",
        "delete_image": "Delete image",
        "add_characteristic": "Add feature",
        "show_owner_info": "Show information about the owner",
        "hide_owner_info": "Hide Owner Info",
        "download_images": "Upload Images",
        "refresh": "Refresh",
        "show_description": "Show Description",
        "pay": "Pay",
        "actions": "Actions",
        "download_invoice": "Download invoice",
        "cancel": "Cancel",
        "contact": "Contact",
        "clone_and_disable": "Clone and disable"
    },
    "navigation": {
        "dashboard": "Dashboard",
        "products": "Products",
        "devices": "Devices",
        "orders": "Orders",
        "users": "Users",
        "roles": "Roles",
        "permissions": "Permissions",
        "status": "Status",
        "taxes": "Taxes"
    },
    "validations": {
        "alpha": "This field may only contain alphabetic characters",
        "alpha_num": "This field may only contain alpha-numeric characters",
        "alpha_dash": "This field may contain alpha-numeric characters as well as dashes and underscores",
        "alpha_spaces": "This field may only contain alphabetic characters as well as spaces",
        "between": "This field must be between {min} and {max}",
        "confirmed": "This field confirmation does not match",
        "digits": "This field must be numeric and exactly contain {length} digits",
        "dimensions": "This field must be {width} pixels by {height} pixels",
        "email": "This field must be a valid email",
        "excluded": "This field is not a valid value",
        "ext": "This field is not a valid file",
        "image": "This field must be an image",
        "integer": "This field must be an integer",
        "length": "This field must be {length} long",
        "max_value": "This field must be {max} or less",
        "max": "This field may not be greater than {length} characters",
        "mimes": "This field must have a valid file type",
        "min_value": "This field must be {min} or more",
        "min": "This field must be at least {length} characters",
        "numeric": "This field may only contain numeric characters",
        "oneOf": "This field is not a valid value",
        "regex": "The field must contain between 6 and 12 characters, at least one number, one uppercase letter and one special character (@$!%*?&).",
        "required_if": "This field is required",
        "required": "This field is required",
        "size": "This field size must be less than {size}KB",
        "double": "This field must be a valid decimal",
        "is": "This field does not match",
        "unique": "This field must be unique"
    }
}

export default en
