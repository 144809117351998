import React, { useCallback, useState } from 'react';

export const ModalContext = React.createContext({
  setModal: (modal: React.ReactNode | null) => {},
  unSetModal: () => {},
});

const Modal: React.FC<{ modal: React.ReactNode }> = ({ modal }) => {
  return <div className="modal">{modal}</div>;
};

export const ModalProvider = (props: any) => {
  const [modal, setModal] = useState<React.ReactNode | null>();
  const unSetModal = useCallback(() => {
    setModal(null);
  }, [setModal]);

  return (
    <ModalContext.Provider value={{ unSetModal, setModal }} {...props}>
      {props.children}
      {modal && <Modal modal={modal} />}
    </ModalContext.Provider>
  );
};
