import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import themeLight from './themes/scouting-light';
import { QueryClientProvider, QueryClient } from 'react-query';
import { CartProvider } from 'react-use-cart';
import { AuthProvider } from './context/authContext';
import { SnackbarProvider } from 'notistack';
import { ModalProvider } from './context/modalContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
});

ReactDOM.render(
  <CartProvider>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <AuthProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themeLight}>
              <ModalProvider>
                <App />
              </ModalProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </AuthProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  </CartProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
