import { API_ENDPOINTS } from 'helpers/ApiEndpoints';
import { JWT_KEY } from 'helpers/Constants';
import { UserCredentials, UserPasswordInformation, UserRegistrationInformation } from 'types/user';
import AxiosFactory from 'helpers/AxiosFactory';

const fetchClient = AxiosFactory.getInstance();

function handleLoginResponse({ access_token }: { access_token: string }) {
  window.localStorage.setItem(JWT_KEY, access_token);
}

function getUser() {
  const token = getToken();
  if (!token) {
    return Promise.resolve(null);
  }
  return fetchClient(`${API_ENDPOINTS.PRIVATE.GET_USER}`).catch((error) => {
    logout();
    return Promise.reject(error);
  });
}

function getToken() {
  return window.localStorage.getItem(JWT_KEY);
}

async function login({ email, password, device }: UserCredentials) {
  try {
    const response = await fetchClient.post(API_ENDPOINTS.PUBLIC.LOGIN, {
      email,
      password,
      device,
    });
    const { data } = response;
    return handleLoginResponse(data);
  } catch (e) {
    return Promise.reject(e);
  }
}
async function emailConfirmation({
  userId,
  hash,
  expires,
  signature,
}: {
  userId: string;
  hash: string;
  expires: string;
  signature: string;
}) {
  return await fetchClient.get(
    API_ENDPOINTS.PUBLIC.EMAIL_CONFIRMATION(userId, hash, expires, signature),
  );
}

async function register({
  email,
  name,
  last_name,
  company_name,
  street_address,
  post_code,
  suburb,
  city,
  state,
  country_id,
  vat,
  password,
  password_confirmation,
  type,
}: UserRegistrationInformation) {
  const payload = {
    email,
    name,
    last_name,
    company_name,
    street_address,
    post_code,
    suburb,
    city,
    state,
    country_id,
    vat,
    password,
    type,
    password_confirmation,
  };

  if (!vat) {
    delete payload.vat;
  }
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.REGISTER, payload);
}

async function forgotPassword({ email }: { email: string }) {
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.FORGOT_PASSWORD, { email });
}
async function changePassword({
  password,
  newPassword,
}: {
  password: string;
  newPassword: string;
}) {
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.CHANGE_PASSWORD, {
    password,
    new_password: newPassword,
  });
}

async function resetPassword(
  token: string,
  email: string,
  { password, password_confirmation }: UserPasswordInformation,
) {
  return await fetchClient.post(API_ENDPOINTS.PUBLIC.RESET_PASSWORD, {
    email,
    token,
    password,
    password_confirmation,
  });
}

function logout() {
  window.localStorage.removeItem(JWT_KEY);
  return Promise.resolve();
}

export {
  login,
  register,
  emailConfirmation,
  logout,
  forgotPassword,
  resetPassword,
  changePassword,
  getToken,
  getUser,
};
