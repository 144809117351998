import Avatar from '@mui/material/Avatar';
import IcPassword from 'components/Icons/IcPassword';
import { useAuth } from 'context/authContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, LinkProps, useNavigate } from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';

import { LANGUAGES } from 'helpers/Constants';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { User } from '../types/user';
import useUser from '../hooks/useUser';
import IcLanguage from './Icons/IcLanguage';
import IcArrowUp from 'components/Icons/IcArrowUp';
import IcArrowDown from 'components/Icons/IcArrowDown';

const getUSerImage = (user: User) => {
  return <img src="/assets/images/missing.png" alt="user avatar" />;
};

const langEquivalent: { [key: string]: string } = {
  'es-419': 'es-ES',
};

const AdapterLink = React.forwardRef<HTMLAnchorElement, LinkProps>((props, ref) => (
  <Link ref={ref as any} {...props} />
));

type Props = {
  language: string;
  open: boolean;
  onClose: () => void;
};

const RightDrawer: React.FC<Props> = ({ language, open, onClose }) => {
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const user = useUser();
  const { logout } = useAuth();

  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLogout = async () => {
    navigate('/');
    if (logout) {
      await logout();
      onClose();
    }
  };

  const handleLanguageChange = (selectedLanguage: string) => {
    i18n.changeLanguage(selectedLanguage);
    setMenuOpen(!menuOpen);
    onClose();
  };

  return (
    <Drawer
      elevation={0}
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{ className: 'bg-black' }}>
      <List component="nav" className="min-w-[300px]" aria-labelledby="nested-list-subheader">
        {user && (
          <>
            <ListItem alignItems="flex-start" className="h-[82px]">
              <ListItemIcon>
                <Avatar alt={`${user.name}`}>{getUSerImage(user)}</Avatar>
              </ListItemIcon>
              <ListItemText
                disableTypography
                className="text-nobel-300 grid text-center justify-center text-slate-50"
                primary={t('My Profile')}
                secondary={
                  <Link
                    to="/"
                    className="pointer-events-none border min-w-[100px] border-slate-300 text-slate-50 font-bold py-1 text-xs m-2 transition duration-300 ease select-none hover:bg-white hover:text-black focus:outline-none focus:ring"
                    onClick={onClose}>
                    {t('View Profile')}
                  </Link>
                }
              />
            </ListItem>

            <Divider className="bg-slate-500" />
            <ListItem
              button
              component={AdapterLink}
              to="/change-password"
              className="text-slate-50 hover:bg-gray-900  h-[82px]"
              onClick={onClose}>
              <ListItemIcon>
                <IcPassword className="text-lg text-slate-50" />
              </ListItemIcon>
              <ListItemText disableTypography className="" primary={t('Change Password')} />
            </ListItem>
            <Divider className="bg-slate-500" />
          </>
        )}
        {/*<ListItem
          button
          className="text-slate-50 hover:bg-gray-900 h-[82px]"
          onClick={handleClick}>
          <ListItemIcon>
            <IcLanguage className="text-slate-50 text-lg" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            className="text-nobel-300"
            primary={LANGUAGES[langEquivalent[language] || language]}
          />
          {menuOpen ? <IcArrowUp /> : <IcArrowDown />}
        </ListItem>
        <Collapse in={menuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {Object.keys(LANGUAGES).map((languageKey) => (
              <ListItem
                key={languageKey}
                button
                className="text-slate-50 hover:bg-gray-900 pl-6"
                onClick={() => handleLanguageChange(languageKey)}>
                <ListItemText
                  disableTypography
                  className="text-nobel-300"
                  primary={LANGUAGES[languageKey]}
                />
              </ListItem>
            ))}
          </List>
        </Collapse>*/}
        <Divider className="bg-slate-500" />
        {user && (
          <>
            <ListItem
              button
              onClick={handleLogout}
              className="text-slate-50 hover:bg-gray-900  h-[82px]">
              <ListItemIcon>
                <ExitToAppIcon className="text-slate-50" />
              </ListItemIcon>
              <ListItemText
                className="text-nobel-300 capitalize"
                disableTypography
                primary={t('Log Out')}
              />
            </ListItem>
            <Divider className="bg-nobel-900" />
          </>
        )}
      </List>
    </Drawer>
  );
};

export default RightDrawer;
