import { useAuth } from '../context/authContext';

const useUser = () => {
  const {
    data: { user },

  } = useAuth();

  return user;
};

export default useUser;
