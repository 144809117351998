import { useForm, Controller, useWatch } from 'react-hook-form';
import { useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import formatErrors from '../../helpers/formatErrors';
import { Container, TextField, InputAdornment } from '@mui/material';
import { showError } from '../../helpers/ValidationHelpers';
import Button from '../../components/Buttons/Button';
import useModal from '../../hooks/useModal';
import PasswordChangeSuccessModal from '../../components/modals/PasswordChangeSuccessModal';
import IcPassword from '../../components/Icons/IcPassword';
import { PASSWORD_REGEX } from '../../helpers/Constants';
import useUser from '../../hooks/useUser';

type ForgotPasswordFormDefinition = {
  password: string;
  new_password: string;
  new_password_confirmation: string;
};

const ChangePassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormDefinition>({
    defaultValues: { password: '', new_password: '', new_password_confirmation: '' },
  });
  const navigate = useNavigate();
  const user = useUser();
  const { changePassword } = useAuth();
  const [isPending, setIsPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setModal, unSetModal } = useModal();
  const password = useWatch({ name: 'new_password', control });
  const { t } = useTranslation();
  const onSubmit = async (values: ForgotPasswordFormDefinition) => {
    setIsPending(true);
    try {
      if (changePassword) {
        await changePassword(values.password, values.new_password);
        setModal(
          <PasswordChangeSuccessModal
            onClose={unSetModal}
            onGoToLink={(link: string) => {
              navigate(link);
              unSetModal();
            }}
          />,
        );
      }
      setIsPending(false);
    } catch (e: any) {
      formatErrors(e).map((error: string) => enqueueSnackbar(error, { variant: 'error' }));
      setIsPending(false);
    }
  };

  if (!user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="min-h-[calc(100vh-56px-30px)]">
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl ">CHANGE PASSWORD</h1>
          </div>
        </Container>
      </div>
      <Container maxWidth="sm">
        <form className="flex flex-col items-center h-full mt-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-10 max-w-xl w-full">
            <div className="flex flex-col sm-portrait:flex-row md:flex-col">
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="password"
                  rules={{ required: t<string>('Please input your Password!') }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      error={Boolean(errors.password)}
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcPassword className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      label={t('Password')}
                      className="w-full"
                      placeholder={t('Password')}
                      helperText={showError(errors, 'password', ['required'])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="new_password"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                    pattern: {
                      value: PASSWORD_REGEX,
                      message: t<string>(
                        'Password must have at least 8 character and contain at least one number',
                      ),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      {...field}
                      label={
                        <>
                          {t('New Password')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcPassword className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors.new_password)}
                      placeholder={t('New Password')}
                      helperText={showError(errors, 'new_password', ['required', 'validate'])}
                    />
                  )}
                />
              </div>
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="new_password_confirmation"
                  defaultValue=""
                  rules={{
                    required: t<string>('This field is required!'),
                    validate: (value) => value === password || t<string>('Password must match!'),
                  }}
                  render={({ field }) => (
                    <TextField
                      variant="standard"
                      {...field}
                      label={
                        <>
                          {t('Password Confirmation')}
                          <span className="text-red-500 inline-block ml-1">*</span>
                        </>
                      }
                      className="w-full"
                      type="password"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcPassword className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      error={Boolean(errors.new_password_confirmation)}
                      placeholder={t('New Password Confirmation')}
                      helperText={showError(errors, 'new_password_confirmation', [
                        'required',
                        'validate',
                      ])}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-6">
            <Button disabled={isPending} type="submit">
              {t('Change Password')}
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ChangePassword;
