import Axios, { AxiosInstance } from 'axios';
import { API_ENDPOINTS } from './ApiEndpoints';
import { JWT_KEY } from './Constants';
import i18n from 'i18n';

class AxiosFactory {
  public axiosInstance: AxiosInstance;
  private languages: { [key: string]: string } = {
    'us-EN': 'en',
    'fr-FR': 'fr',
    'es-ES': 'es',
  };
  constructor() {
    const axiosInstance = Axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });

    axiosInstance.interceptors.request.use((config) => {
      const token = window.localStorage.getItem(JWT_KEY);
      if (!token || (config.url && (config.url.includes('https') || config.url.includes('http')))) {
        return config;
      }
      return {
        ...config,
        headers: {
          ...config.headers,
          authorization: `Bearer ${token}`,
        },
      };
    });

    axiosInstance.interceptors.request.use((config) => {
      return {
        ...config,
        headers: {
          ...config.headers,
          'Content-Language': this.languages[i18n.language] || 'en',
        },
      };
    });

    axiosInstance.interceptors.response.use(
      (response) => response,
      function (error) {
        if (
          error.response.status === 401 &&
          error.response.config.url !== API_ENDPOINTS.PUBLIC.LOGIN &&
          error.response.config.url !== API_ENDPOINTS.PUBLIC.RESET_PASSWORD &&
          error.response.config.url !== API_ENDPOINTS.PUBLIC.EMAIL_CONFIRMATION
        ) {
        //  window.location.href = '/login?redirect=' + window.location.pathname;
        }
        return Promise.reject(error);
      },
    );

    this.axiosInstance = axiosInstance;
  }

  getInstance = (): AxiosInstance => {
    return this.axiosInstance;
  };
}

export default new AxiosFactory();
