import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import clsx from "clsx";

const IcEdit: React.FC<SvgIconProps> = ({ className, ...props }) => {
  return (
    <SvgIcon className={clsx(className)} width="1em" height="1em" viewBox="0 0 24 24" {...props}>
      <path d="M 18.414062 2 C 18.158062 2 17.902031 2.0979687 17.707031 2.2929688 L 15.707031 4.2929688 L 14.292969 5.7070312 L 3 17 L 3 21 L 7 21 L 21.707031 6.2929688 C 22.098031 5.9019687 22.098031 5.2689063 21.707031 4.8789062 L 19.121094 2.2929688 C 18.926094 2.0979687 18.670063 2 18.414062 2 z M 18.414062 4.4140625 L 19.585938 5.5859375 L 18.292969 6.8789062 L 17.121094 5.7070312 L 18.414062 4.4140625 z M 15.707031 7.1210938 L 16.878906 8.2929688 L 6.171875 19 L 5 19 L 5 17.828125 L 15.707031 7.1210938 z" />
    </SvgIcon>
  );
};

export default IcEdit;
