import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IcMail: React.FC<SvgIconProps> = ({ className, ...props }) => {
  return (
    <SvgIcon classes={{ root: className }} viewBox="0 0 17 13" {...props}>
      <path
        className="st0"
        d="M16.9,10.5l0-9.3c0-0.3,0-0.4,0-0.5h0c0,0,0,0,0-0.1l0,0c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0-0.1l0,0
	c-0.1-0.1-0.2-0.1-0.3-0.2l0,0l-15.7,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0l0,0c0,0,0,0-0.1,0.1l0,0c0,0,0,0,0,0.1l0,0
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1l0,0c0,0,0,0,0,0.1v11.7c0,0.2,0.1,0.4,0.3,0.5c0.1,0,0.1,0,0.2,0h15.7c0.1,0,0.3,0,0.4-0.1l0,0
	l0,0c0.1-0.1,0.1-0.2,0.1-0.4h0c0-0.1,0-0.2,0-0.5L16.9,10.5z M2.2,1.2h12.6L8.6,6.6L2.2,1.2z M11,7c-0.1,0.1-0.2,0.2-0.2,0.4
	c0,0.1,0.1,0.3,0.2,0.4l4,4H2l4-4c0.1-0.1,0.2-0.2,0.2-0.4C6.1,7.3,6.1,7.1,6,7C5.8,6.8,5.4,6.8,5.2,7l-4,4V1.9l7,5.9
	C8.5,8,8.8,8,9,7.8l6.8-5.9V11l-4-4h0C11.6,6.8,11.2,6.8,11,7z"
      />
    </SvgIcon>
  );
};

export default IcMail;
