import { VFC } from 'react';
import { DialogContent, Dialog, Button } from '@mui/material';
import PaymentForm from '../PaymentForm/PaymentForm';

const PaymentModal: VFC<{
  onClose: () => void;
  products: any[];
}> = ({ onClose, products }) => {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" scroll="body">
      <DialogContent className="pt-6 px-0 pb-0 flex items-center overflow-hidden flex-col min-w-[340px] min-h-[355px]">
          <img src="/assets/images/plain_engarde_logo.svg" className="my-2 max-w-[56px]" alt="" />
          <PaymentForm products={products} />
      </DialogContent>
    </Dialog>
  );
};

export default PaymentModal;
