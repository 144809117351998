import get from 'lodash/get';
import memoize from 'lodash/memoize';
import { AxiosError } from 'axios';
import { isObject } from './formatErrors';

const getErrorMessageFromAPI = memoize(
  (error: AxiosError, defaultMessage: string = ''): string | string[] => {
    // the response.data.error is for compatibility with the mock-server
    const errorMessage =
      get(error, 'error', '') ||
      get(error, 'response.data.message', '') ||
      get(error, 'response.data.error', '') ||
      get(error, 'response.data', '');

    if (errorMessage && isObject(errorMessage)) {
      return Object.values(errorMessage);
    }
    if (errorMessage) {
      return errorMessage;
    }
    const errorMessages = get(error, 'errors', '') || get(error, 'response.data.errors', '');
    if (errorMessages) {
      return errorMessages;
    }

    const errorInstanceMessage = get(error, 'message', '');
    if (errorInstanceMessage) {
      return errorInstanceMessage;
    }

    return defaultMessage;
  },
);

export default getErrorMessageFromAPI;
