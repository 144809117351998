import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Link, useMatch, useNavigate } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import IcMenu from 'components/Icons/IcMenu';
import Avatar from '@mui/material/Avatar';
import useUser from 'hooks/useUser';
import Box from '@mui/material/Box';
import IcCart from './Icons/IcCart';
import { useCart } from 'react-use-cart';

type Props = {
  onToggle: () => void;
  onNotificationClick?: () => void;
  runningAlgorithms?: any[];
  onAlgorithmsClick?: () => void;
};

const MenuAppBar: React.FC<Props> = ({ onToggle }) => {
  const { t } = useTranslation();
  const user = useUser();
  const match = useMatch('/login');
  const matchCheckout = useMatch('/checkout');
  const matchRegister = useMatch('/register/invitation');
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate('/login');
  };

  const handleCheckoutClick = () => {
    if (matchCheckout) {
      navigate('/');
      return;
    }
    navigate('/checkout');
  };
  const handleDrawerToggle = () => {
    onToggle();
  };
  const { totalItems } = useCart();

  const getUSerImage = () => {
    return <img src="/assets/images/missing.png" alt="user avatar" />;
  };

  return (
    <AppBar
      position="static"
      elevation={0}
      color="default"
      className={clsx('bg-black text-nobel-300')}>
      <Toolbar className={'p-0 flex content-between gap-4'}>
        <div className="flex text-center hidden sm:block items-center flex-1 z-10">
          <Link to="/" className="flex items-center">
            <img
              className="cursor-pointer h-8 px-2"
              src="/assets/images/logo.png"
              alt="Scouting logo"
            />
          </Link>
        </div>
        <Box className="ml-2 sm:ml-0 flex text-center items-center justify-center flex-1 flex sm:absolute top-0 left-0 bottom-0 w-full">
          <Link to="/" className="flex items-center">
            <img
              className="cursor-pointer lg:h-12"
              src="/assets/images/engarde_logo.svg"
              alt="Scouting logo"
            />
          </Link>
        </Box>
        <div className="flex text-white items-center">
          <Button
            className={`mr-2 px-3 ${
              totalItems > 0 && `bg-lightblue text-white rounded-full hover:bg-lightblue-700`
            } ${totalItems <= 0 && 'text-white'}`}
            onClick={handleCheckoutClick}
            sx={{
              minWidth: 0,
              height: 40,
              '&.Mui-disabled': {
                color: '#fff',
              },
            }}
            size="small"
            aria-label={t('Log In')}
            color="inherit">
            <IcCart className="mr-2" />{' '}
            {totalItems > 0 && (
              <span className="text-xs flex">
                <span className="hidden sm:inline">
                  {matchCheckout ? 'Add More Products' : 'Place Order'}
                </span>
                <div className="text-lightblue bg-white text-center tabular-nums rounded-full ml-2 px-1">
                  {totalItems}
                </div>
              </span>
            )}
          </Button>
          {!user && !match && !matchRegister && (
            <Button
              className=" hover:underline"
              onClick={handleLoginClick}
              aria-label={t('Log In')}
              color="inherit">
              <svg
                width="17"
                height="24"
                className="mr-2"
                viewBox="0 0 17 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.07445 0.5C5.26594 0.5 2.92552 2.94681 2.92552 5.88298V8.08511H0.702126C0.351063 8.08511 0 8.45213 0 8.94149V15.0585C0 19.7074 3.62765 23.5 8.07445 23.5C12.5212 23.5 16.1489 19.7074 16.1489 15.0585V8.94149C16.1489 8.45213 15.7978 8.08511 15.3297 8.08511H13.2234V5.88298C13.2234 2.82447 10.883 0.5 8.07445 0.5ZM4.4468 5.88298C4.4468 3.80319 6.08509 2.09043 8.07445 2.09043C10.0638 2.09043 11.7021 3.80319 11.7021 5.88298V8.08511H4.4468V5.88298ZM14.6276 9.79787V15.0585C14.6276 18.8511 11.7021 21.9096 8.07445 21.9096C4.4468 21.9096 1.52127 18.8511 1.52127 15.0585V9.79787H14.6276Z"
                  fill="#DCDCDC"
                />
                <path
                  d="M8.07501 12.7339C7.60692 12.7339 7.25586 13.1009 7.25586 13.5903V16.6488C7.25586 17.1381 7.60692 17.5052 8.07501 17.5052C8.54309 17.5052 8.89415 17.1381 8.89415 16.6488V13.5903C8.89415 13.1009 8.54309 12.7339 8.07501 12.7339Z"
                  fill="#DCDCDC"
                />
              </svg>
              {t('Log In')}
            </Button>
          )}
          {user && (
            <>
              <Button onClick={handleDrawerToggle}>
                <Avatar sx={{ width: 35, height: 35 }} alt={`${user.name}`}>
                  {getUSerImage()}
                </Avatar>
              </Button>
            </>
          )}
          {!user && (
            <IconButton
              edge="start"
              className="ml-1"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              size="large">
              <IcMenu className="text-xl" />
            </IconButton>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default MenuAppBar;
