import useUser from '../../hooks/useUser';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { UserRegistrationInformation } from '../../types/user';
import { Country } from '../../pages/CreateAccount/CreateAccount';
import { useQuery } from 'react-query';
import AxiosFactory from '../../helpers/AxiosFactory';
import React, { useEffect, VFC } from 'react';
import { EMAIL_REGEX } from '../../helpers/Constants';
import { TextField, Radio } from '@mui/material';
import { showError } from '../../helpers/ValidationHelpers';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '../Buttons/Button';
import { useTranslation } from 'react-i18next';
import { API_ENDPOINTS } from '../../helpers/ApiEndpoints';
import { useAuth } from '../../context/authContext';
import { useSnackbar } from 'notistack';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

type CustomerInfoFormType = Omit<
  UserRegistrationInformation,
  'password' | 'password_confirmation'
> & {
  country: Country | '' | undefined;
};

const CustomerInfoForm: VFC<{ onSuccess: () => void }> = ({ onSuccess }) => {
  const user = useUser();
  const { data: countries } = useQuery<{ data: Country[] }>(
    'countries',
    async () => await AxiosFactory.getInstance().get('guest/countries'),
  );
  const { reloadUser } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm<CustomerInfoFormType>({
    defaultValues: {
      email: user?.email || '',
      name: user?.name || '',
      last_name: user?.client?.last_name || '',
      company_name: user?.client?.company_name || '',
      street_address: user?.client?.street_address || '',
      post_code: user?.client?.post_code || '',
      suburb: user?.client?.suburb || '',
      city: user?.client?.city || '',
      state: user?.client?.state || '',
      country_id: user?.client?.country_id || '',
      vat: user?.client?.vat || '',
      type: user?.client?.type.toString() || '1',
      country: '',
    },
  });
  const type = useWatch({ name: 'type', control });

  const onSubmit = async (values: CustomerInfoFormType) => {
    try {
      const fetchClient = AxiosFactory.getInstance();
      await fetchClient.post(API_ENDPOINTS.PRIVATE.UPDATE_USER, values);
      await reloadUser?.();
      enqueueSnackbar(t('User information updated successfully'), { variant: 'success' });
      onSuccess();
    } catch (e) {}
  };

  useEffect(() => {
    const formValues = getValues();
    if (!formValues.country && user && countries) {
      const country = countries.data.find((country) => country.id === formValues.country_id);
      setValue('country', country);
    }
  }, [countries, getValues, setValue, user]);
  return (
    <form
      className="overflow-hidden flex flex-col align-middle w-full h-full"
      onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col sm-portrait:flex-row md:flex-col">
        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="email"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
              pattern: {
                value: EMAIL_REGEX,
                message: t<string>('You must enter a valid Email address!'),
              },
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                disabled
                label={
                  <>
                    {t('Email')}
                    <span className="text-red-500 inline-block ml-1">*</span>
                  </>
                }
                className="w-full"
                error={Boolean(errors.email)}
                helperText={showError(errors, 'email', ['required', 'pattern'])}
              />
            )}
          />
        </div>

        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="name"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
            }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="standard"
                label={
                  <>
                    {t('Full Name')}
                    <span className="text-red-500 inline-block ml-1">*</span>
                  </>
                }
                className="w-full"
                error={Boolean(errors.name)}
                helperText={showError(errors, 'name', ['required'])}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full">
          <Controller
            control={control}
            name="street_address"
            defaultValue=""
            rules={{
              required: t<string>('This field is required!'),
            }}
            render={({ field }) => (
              <TextField
                variant="standard"
                {...field}
                label={t('Address (including city, state or province and post code)')}
                className="w-full"
                multiline
                rows={3}
                name="street_address"
                error={Boolean(errors.last_name)}
                helperText={showError(errors, 'street_address', ['required'])}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full">
          <Controller
            name="country"
            control={control}
            rules={{ required: t<string>('This field is required!') }}
            render={({ field }) => (
              <Autocomplete
                className="w-full"
                options={countries?.data || []}
                value={field.value}
                onChange={(e, value) => {
                  field.onChange(value);
                  setValue('country_id', value ? value?.id : '');
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    error={Boolean(errors.country)}
                    helperText={showError(errors, 'country', ['required'])}
                    label={
                      <>
                        {t('Country')}
                        <span className="text-red-500 inline-block ml-1">*</span>
                      </>
                    }
                    margin="normal"
                  />
                )}
                getOptionLabel={(option) => (option as Country).name}
              />
            )}
          />
        </div>
        <div className="mb-10 w-full border rounded-lg">
          <div className="bg-black text-white px-4 py-2 rounded-t-lg">Billing Information</div>
          <div className="px-4">
            <Controller
              control={control}
              name="type"
              defaultValue="1"
              rules={{
                required: t<string>('This field is required!'),
              }}
              render={({ field }) => (
                <RadioGroup aria-labelledby="demo-radio-buttons-group-label" {...field}>
                  <FormControlLabel value="1" control={<Radio />} label="Personal" />
                  <FormControlLabel value="2" control={<Radio />} label="Professional" />
                </RadioGroup>
              )}
            />
          </div>
        </div>

          {type === '2' && (
              <>
                  <div className="mb-10 w-full">
                      <Controller
                          control={control}
                          name="company_name"
                          defaultValue=""
                          render={({ field }) => (
                              <TextField
                                  variant="standard"
                                  {...field}
                                  label={t('Club / Federation / Organism Name')}
                                  className="w-full"
                                  name="company_name"
                                  error={Boolean(errors.last_name)}
                                  helperText={showError(errors, 'company_name', ['required'])}
                              />
                          )}
                      />
                  </div>
                  <div className="mb-10 w-full">
                      <Controller
                          control={control}
                          name="vat"
                          defaultValue=""
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  variant="standard"
                                  label={t('VAT Number')}
                                  className="w-full"
                                  error={Boolean(errors.vat)}
                                  helperText={showError(errors, 'vat', ['pattern'])}
                              />
                          )}
                      />
                  </div>
              </>
          )}
      </div>
      <div className="text-center mb-4">
        <Button type="submit">{t('Update Account')}</Button>
      </div>
    </form>
  );
};

export default CustomerInfoForm;
