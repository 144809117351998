import React from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const IcMenu: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 23 23" {...props}>
      <g>
        <circle className="st2" cx="11.5" cy="3" r="2" />
        <circle className="st2" cx="11.5" cy="11.5" r="2" />
        <circle className="st2" cx="11.5" cy="20" r="2" />
      </g>
    </SvgIcon>
  );
};

export default IcMenu;
