import React from 'react';
import { useAsync } from 'react-async';
import * as authActions from 'api/requests/auth';
import FullPageSpinnerLogo from 'components/FullPageSpinnerLogo';
import {
  User,
  UserCredentials,
  UserPasswordInformation,
  UserRegistrationInformation,
} from 'types/user';
import { AxiosResponse } from 'axios';
import { getUser } from 'api/requests/auth';

type AuthProps = {
  data: {
    user: User | null;
  };
  login?: (form: UserCredentials) => Promise<void>;
  logout?: () => Promise<void>;
  emailConfirmation?: (
    userId: string,
    hash: string,
    expires: string,
    signature: string,
  ) => Promise<AxiosResponse>;
  register?: (form: UserRegistrationInformation) => Promise<AxiosResponse>;
  forgotPassword?: (email: string) => Promise<AxiosResponse<any>>;
  resetPassword?: (
    token: string,
    email: string,
    form: UserPasswordInformation,
  ) => Promise<AxiosResponse<any>>;
  changePassword?: (password: string, new_password: string) => Promise<AxiosResponse<any>>;
  reloadUser?: () => void;
};
const AuthContext = React.createContext<AuthProps>({ data: { user: null } });

async function bootstrapAppData() {
  try {
    const response = await getUser();
    const data = response?.data;
    return {
      user: data,
    };
  } catch (e) {
    return { user: null };
  }
}

const AuthProvider = (props: any) => {
  const [firstAttemptFinished, setFirstAttemptFinished] = React.useState(false);
  const {
    data = { user: null },
    isRejected,
    isPending,
    isSettled,
    reload,
  } = useAsync({
    promiseFn: bootstrapAppData,
  });

  React.useLayoutEffect(() => {
    if (isSettled) {
      setFirstAttemptFinished(true);
    }
  }, [isSettled]);

  if (!firstAttemptFinished) {
    if (isPending) {
      return <FullPageSpinnerLogo />;
    }
    if (isRejected) {
      //TODO: style error page
      return <div>Error</div>;
    }
  }

  const login = async (form: UserCredentials) => {
    await authActions.login(form);
    await reload();
  };
  const register = (form: UserRegistrationInformation) => authActions.register(form);
  const logout = () => authActions.logout().then(reload);
  const forgotPassword = (email: string) => authActions.forgotPassword({ email });
  const changePassword = (password: string, newPassword: string) =>
    authActions.changePassword({ password, newPassword });
  const emailConfirmation = (userId: string, hash: string, expires: string, signature: string) =>
    authActions.emailConfirmation({ userId, hash, expires, signature });
  const resetPassword = (token: string, email: string, form: UserPasswordInformation) =>
    authActions.resetPassword(token, email, form);
  return (
    <AuthContext.Provider
      value={{
        data,
        login,
        logout,
        emailConfirmation,
        register,
        forgotPassword,
        resetPassword,
        changePassword,
        reloadUser: reload,
      }}
      {...props}
    />
  );
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }
  return context;
}

export { AuthProvider, useAuth };
