export const showError = (errors: any, field: string, types: string[] = []): string => {
  let error = '';
  if (Boolean(errors[field])) {
    types.forEach(type => {
      if (errors[field].type === type) {
        error = errors[field].message;
      }
    });
  }

  return error;
};

