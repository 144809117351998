import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const FullPageSpinnerLogo = () => {
  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}>
      <CircularProgress size={100} thickness={2} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <img src="/assets/images/plain_engarde_logo.svg" alt="logo" width={50} />
      </Box>
    </Box>
  );
};

export default FullPageSpinnerLogo;
