const getDeviceName = ()=> {
    if (!navigator.appCodeName || !navigator.platform) {
        let firstIndex = navigator.userAgent.indexOf('(');
        let lastIndex = navigator.userAgent.indexOf(')');
        return navigator.userAgent.substring(firstIndex + 1, lastIndex);
    }

    return navigator.appCodeName + " (" + navigator.platform + ")";
}

export default getDeviceName
