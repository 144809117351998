import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useState, VFC, useEffect } from 'react';
import { Button, CircularProgress } from '@mui/material';

const CheckoutForm: VFC<{ clientSecret: string }> = ({ clientSecret }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      'payment_intent_client_secret',
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: window.location.protocol + '//' + window.location.host + '/payment/status',
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === 'card_error' || error.type === 'validation_error') {
      setMessage(error.message);
    } else {
      setMessage('An unexpected error occurred.');
    }

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="pt-6 px-6 pb-1">
        {message && <div id="payment-message">{message}</div>}
        <PaymentElement id="payment-element" onReady={() => setIsLoading(false)} />
      </div>
      <>
        <div className="text-xs text-slate-400 w-full text-right pb-4 px-6">Powered by Stripe</div>
        <button
          className="w-full rounded-t-none bg-lightblue text-white hover:bg-lightblue-700 py-3"
          disabled={isLoading || !stripe || !elements}
          id="submit">
          <span id="button-text flex items-center">
            {isLoading ? <CircularProgress className="text-white" size={16} /> : 'Pay now'}
          </span>
        </button>
      </>
      {/* Show any error or success messages */}
    </form>
  );
};

export default CheckoutForm;
