import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/authContext';
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import formatErrors from '../../helpers/formatErrors';
import { Container, TextField, InputAdornment } from '@mui/material';
import IcMail from '../../components/Icons/IcMail';
import { showError } from '../../helpers/ValidationHelpers';
import Button from '../../components/Buttons/Button';
import { EMAIL_REGEX } from '../Login/Login';
import useModal from '../../hooks/useModal';
import PasswordResetSuccessModal from '../../components/modals/PasswordResetSuccessModal';
import PasswordResetSentSuccessModal from '../../components/modals/PasswordResetSentSuccessModal';

type ForgotPasswordFormDefinition = {
  email: string;
};

const ForgotPassword = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordFormDefinition>({ defaultValues: { email: '' } });
  const navigate = useNavigate();
  const { forgotPassword } = useAuth();
  const [isPending, setIsPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { setModal, unSetModal } = useModal();

  const { t } = useTranslation();
  const onSubmit = async (values: ForgotPasswordFormDefinition) => {
    setIsPending(true);
    try {
      if (forgotPassword) {
        await forgotPassword(values.email);
        setModal(
          <PasswordResetSentSuccessModal
            onGoToLink={(link) => {
              navigate(link);
              unSetModal();
            }}
            onClose={unSetModal}
          />,
        );
      }
      setIsPending(false);
    } catch (e: any) {
      formatErrors(e).map((error: string) => enqueueSnackbar(error, { variant: 'error' }));
      setIsPending(false);
    }
  };

  return (
    <div className="min-h-[calc(100vh-56px-30px)]">
      <div className="border-b px-2 py-8 flex w-full justify-center">
        <Container maxWidth="lg">
          <div className="self-center w-full flex justify-between">
            <h1 className="text-2xl ">FORGOT PASSWORD</h1>
          </div>
        </Container>
      </div>
      <Container maxWidth="sm">
        <form className="flex flex-col items-center h-full mt-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="pt-10 max-w-xl w-full">
            <div className="flex flex-col sm-portrait:flex-row md:flex-col">
              <div className="mb-10 w-full">
                <Controller
                  control={control}
                  name="email"
                  rules={{
                    required: t('Please input your email!') as string,
                    pattern: {
                      value: EMAIL_REGEX,
                      message: t('You must enter a valid Email address!'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="standard"
                      error={Boolean(errors.email)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <IcMail className="text-base" />
                          </InputAdornment>
                        ),
                      }}
                      label={t('Email')}
                      placeholder={t('Email')}
                      className="w-full group"
                      helperText={showError(errors, 'email', ['required', 'pattern'])}
                    />
                  )}
                />
              </div>
            </div>
          </div>
          <div className="text-center mt-6">
            <Button disabled={isPending} type="submit">
              {t('Reset Password')}
            </Button>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default ForgotPassword;
