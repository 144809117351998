import { AxiosError } from 'axios';

import getErrorMessageFromAPI from './getErrorMessageFromAPI';
export function isObject(a: any): a is object {
  return a && (typeof a === 'function' || typeof a === 'object');
}
export default function formatErrors(error: any): string[] {
  if (error && Array.isArray(error)) {
    if (error.length < 2) {
      return error;
    }
    return error.reduce((prevError, error) => {
      return prevError.concat(error);
    });
  }
  if (isObject(error)) {
    if ((error as AxiosError).isAxiosError) {
      return processAxiosError(error as AxiosError);
    } else if (error instanceof Error) {
      return [error.message];
    }
  }

  return [error];
}

const processAxiosError = (error: AxiosError): string[] => {
  const errorMessage = getErrorMessageFromAPI(
    error,
    'Oops Something unexpected happened, please reload the page and if the issue persist contact with us.',
  );

  return Array.isArray(errorMessage) ? errorMessage : [errorMessage];
};
