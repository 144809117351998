import { ButtonUnstyled } from '@mui/base';
import { FC } from 'react';
import clsx from 'clsx';
const Button: FC<{
  className?: string;
  disabled?: boolean;
  type?: string;
  onClick?: (e: MouseEvent) => void;
}> = ({ children, className, type = 'button', onClick }) => {
  return (
    <ButtonUnstyled
      type={type}
      onClick={onClick}
      className={clsx(
        `uppercase text-sm px-[30px] tracking-[0.11em] text-[14px] inline-flex font-bold h-[40px] items-center justify-center cursor-pointer rounded-full py-2 px-4 text-white bg-lightblue-500 text‑lightblue-500 hover:bg-lightblue-700 hover:border-transparent`,
          className,
      )}>
      {children}
    </ButtonUnstyled>
  );
};

export default Button;
