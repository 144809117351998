import { VFC } from 'react';
import { DialogContent, Dialog, Button } from '@mui/material';

const AccountCreationSuccessModal: VFC<{
  onClose: () => void;
  onGoToLink: (link: string) => void;
}> = ({ onClose, onGoToLink }) => {
  const handleGoToLogin = () => {
    onGoToLink('/');
  };
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" scroll="body">
      <DialogContent className="p-0 flex justify-center items-center flex-col min-w-[400px]">
        <img src="/assets/images/plain_engarde_logo.svg" className="my-12 max-w-[56px]" alt="" />
        <h1 className="text-center text-xl tracking-widest uppercase max-w-[300px]">
          Password Reset Email Sent
        </h1>

        <div className="max-w-[320px]">
          <p className="my-6 text-center">Check your inbox for the password reset link.</p>
        </div>
        <Button
          onClick={handleGoToLogin}
          className="w-full bg-black text-white py-3 rounded-none hover:bg-slate-800">
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default AccountCreationSuccessModal;
